<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('User Filter') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userList"
            :reduce="(val) => val.id"
            :loading="loading"
            label="userNameAndMail"
            class="w-100"
            @input="(val) => $emit('update:userFilter', val)"
            @search="fetchUsers"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="ml-auto mb-md-0 mb-2"
        >
          <div class="d-flex align-items-center justify-content-end mt-2">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                :value="searchQuery"
                @input="(val) => $emit('update:searchQuery', val)"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </b-input-group>
          </div>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'

import ApiService from '@/common/api.service'

export default {
  components: {
    vSelect,
  },
  props: {
    searchQuery: {
      type: String,
      default: null,
    },
    userFilter: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      userList: [],
      search: '',
      loading: false,
      noDataText: 'Veri bulunamadı',
    }
  },
  methods: {
    fetchUsers(search) {
      /* eslint-disable */
      if (search === 'INITIALIZ-SEARCH') {
        search = '';
      } else {
        if (search.length < 3) {
          this.items = [];
          return;
        }
      }

      this.loading = true;

      ApiService.get(`user/selects/${search}`, {}, this)
        .then(response => {
          this.userList = response.result;
          this.loading = false;
        })
    },
  },
  created() {
    this.fetchUsers('INITIALIZ-SEARCH');
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
